



























































































import Vue from 'vue';
import Excel, {
  Buffer,
  Workbook,
  WorksheetModel,
  Row,
  Column,
  Cell,
  Borders,
} from 'exceljs';
import axios from 'axios';
import { editorOptions, editPdfSave } from '@/api/export';
import { mapGetters } from 'vuex';
import { PdfPageType, EditPdfOptionType } from '@/interface/pdfEdit';

interface CellWithMorePros extends Cell {
  id: string;
  v: string;
  width: number;
  colspan: number;
  styles: Record<string, string>;
  contenteditable: boolean;
  totalIndex: number;
}

interface CellRow extends Row {
  id: string;
  cells: CellWithMorePros[];
  field: string;
}
type ValueType = string | { richText: { text: string }[] };
type SheetModel = WorksheetModel & { rows: CellRow[]; cols: Column[] };

const keyMap = {
  [PdfPageType.coverPage]: {
    showCoverConstructName: {
      index: [0],
      name: '工程名称',
    },
    showCoverConstructTotal: {
      name: '投标总价',
      index: [1],
      field: '',
    },
    showCoverTender: {
      name: '招标人',
      index: [2, 3],
      field: 'coverTender',
    },
    showCoverManagerDate: {
      name: '编制时间',
      index: [7],
      field: 'coverManagementDate',
    },
  },
  [PdfPageType.titlePage]: {
    showTitle: {
      name: '标题',
      index: [0],
      field: '',
    },
    showTitleTender: {
      name: '招标人',
      index: [1],
      field: 'titleTender',
    },
    showTitleConstructName: {
      name: '工程名称',
      index: [2],
      field: '',
    },
    showTitleTotal: {
      name: '投标总价',
      index: [3, 4],
      field: '',
    },
    showTitleBidder: {
      name: '投标人',
      index: [6],
      field: 'titleBidder',
    },
    showTitleRepresentative: {
      name: '法定代表人',
      index: [8],
      field: 'titleRepresentative',
    },
    showTitleCostEngineer: {
      name: '注册造价工程师',
      index: [10],
      field: 'titleCostEngineer',
    },
    showTitleManagementDate: {
      name: '编制时间',
      index: [12],
      field: 'titleManagementDate',
    },
  },
  [PdfPageType.description]: {
    description: {
      name: '说明',
      index: [2],
      field: 'description',
    },
  },
};
// excel高度对应到浏览器px比例
const HEIGHT_RATIO = 0.75;
// excel宽度度对应到浏览器px比例
const WIDTH_RATIO = 0.12;

export default Vue.extend({
  name: 'pdfEditor',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: String,
      default: PdfPageType.coverPage,
    },
    excelUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      rows: [] as CellRow[],
      visibleRows: [] as CellRow[],
      checkedNames: [] as string[],
      workbook: null as Workbook | null,
      formData: {
        cover: {
          coverTender: '',
          coverManagementDate: '',
          showCoverConstructName: false,
          showCoverConstructTotal: true,
          showCoverTender: true,
          showCoverManagerDate: true,
        },
        titlePage: {
          titleTender: '',
          titleBidder: '',
          titleRepresentative: '',
          titleCostEngineer: '',
          titleManagementDate: '',
        },
        constructOfferDesc: {
          description: '',
        },
      } as EditPdfOptionType,
    };
  },
  mounted() {
    this.loadExcel();
    this.getEditorOptions();
  },
  computed: {
    ...mapGetters(['projectBidSequenceNbr']),
    selectors(): Record<string, any> {
      return this.pageType ? keyMap[this.pageType] : [];
    },
    isShowSwitch(): boolean {
      return !!this.workbook && this.pageType !== PdfPageType.description;
    },
  },
  watch: {
    pageType(val: number) {
      if (!this.workbook) return;
      this.parseSheet();
    },
    checkedNames: {
      handler: function () {
        this.checkedNameChangeHandle();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 可操作项修改之后对数据得处理
     */
    checkedNameChangeHandle() {
      if (this.pageType === PdfPageType.description) {
        return;
      }
      const selectors = this.selectors;
      const indexes = this.checkedNames.reduce<number[]>((p, c) => {
        // console.log(p, c, selectors);
        return [...p, ...selectors[c].index];
      }, []);
      const allIndexes = Object.values(selectors).reduce<number[]>(
        (p, c) => [...p, ...c.index],
        []
      );
      this.visibleRows = this.rows.map((row, index) => {
        if (!allIndexes.includes(index) || indexes.includes(index)) {
          return row;
        }
        const { cells, ...others } = row;
        return { ...others, cells: [] };
      });
    },
    async save(isClose = false) {
      const projectBidSequenceNbr = this.projectBidSequenceNbr;
      if (!projectBidSequenceNbr) return;
      this.setSaveEditorOptions();
      this.loading = true;
      const { result, status } = await editPdfSave({
        ...this.formData,
        constructId: projectBidSequenceNbr,
      });
      this.loading = false;
      if (result && status === 200) {
        this.$emit('successCallback');
        this.$message.success('保存成功');
        if (isClose) {
          setTimeout(() => {
            this.cancel();
          }, 1000);
        }
        return;
      }
      this.$message.error('保存失败');
    },
    /**
     * 映射对应得数据
     */
    setSaveEditorOptions() {
      const pageType = this.pageType;
      if (pageType === PdfPageType.description) return;
      const params = this.formData[pageType];
      const showKeys = Object.keys(keyMap[pageType]);
      const checkedNames = this.checkedNames;
      for (let key in params) {
        if (showKeys.includes(key)) {
          this.formData[pageType][key] = checkedNames.includes(key);
        }
      }
    },
    /**
     * 初始是否显示项列表
     */
    initCheckedName() {
      if (this.pageType === PdfPageType.description) return;
      const params = this.formData[this.pageType];
      const showKeys = Object.keys(keyMap[this.pageType]);
      const names: string[] = [];
      for (let key of showKeys) {
        if (params[key]) names.push(key);
      }
      this.checkedNames = names;
    },
    /**
     * 获取默认数据
     */
    getEditorOptions() {
      const projectBidSequenceNbr = this.projectBidSequenceNbr;
      if (!projectBidSequenceNbr) return;
      editorOptions(projectBidSequenceNbr).then(({ result }) => {
        if (result) {
          let description = result.constructOfferDesc.description;
          if (description === null) {
            result.constructOfferDesc.description = '';
          }
          this.formData = result;
          this.loading = false;
          this.initCheckedName();
        }
      });
    },
    updateCell(field, event) {
      if (!field) return;
      const value = event.target.innerText;
      this.formData[this.pageType][field] = value;
    },
    /**
     * 读取excel文件流
     */
    async readFile(): Promise<Buffer> {
      const { data, status } = await axios({
        url: this.excelUrl + '?t=' + new Date().getTime(),
        method: 'GET',
        responseType: 'arraybuffer',
      });
      return new Promise((resolve, reject) => {
        if (status !== 200) return reject('error');
        resolve(data as Buffer);
      });
    },
    /**
     * 读取sheet页
     */
    async loadExcel() {
      if (!this.excelUrl) return;
      const workbook = new Excel.Workbook();
      const buffer = await this.readFile();
      this.workbook = await workbook.xlsx.load(buffer);
      this.parseSheet();
      this.checkedNameChangeHandle();
    },
    /**
     * 解析sheet
     */
    parseSheet() {
      // this.checkedNames = Object.keys(this.selectors);
      const coverSheet = this.workbook?.model.sheets[0];
      this.readRows(coverSheet as SheetModel);
    },
    readRows(sheet: SheetModel) {
      const { rows, cols } = sheet;
      const selectors = this.selectors;
      for (const selector in selectors) {
        const item = selectors[selector];
        const indexArr = item.index;
        indexArr.forEach((index: number) => {
          rows[index].field = item?.field;
        });
      }
      if (this.pageType === PdfPageType.description) {
        rows.splice(rows.length - 1, 1);
      }
      this.rows = rows.map((row, i) => {
        const { cells, field } = row;
        return {
          ...row,
          id: this.createId(),
          cells: this.readCells(cells, cols, field),
          height: row.height / HEIGHT_RATIO,
        };
      });
      this.visibleRows = [...this.rows];
    },
    /**
     * @description: 生成随机字符串
     * @param {*}
     * @return {*}
     */
    createId() {
      return Math.random().toString(36).slice(2, 12);
    },
    /**
     * 解析单元格
     */
    readCells(cells: CellWithMorePros[], cols: Column[], field: string) {
      return cells.reduce<CellWithMorePros[]>((prev, curr, i) => {
        const cellWidth = (cols[i].width || 0) / WIDTH_RATIO;
        const last = prev.slice(-1)[0];
        if (curr.master) {
          if (last?.width) last.width += cellWidth;
          if (last?.colspan) last.colspan += 1;
        } else {
          const styles = this.createStyles(curr);
          prev.push({
            ...curr,
            v: this.createValue(curr.value as ValueType),
            id: this.createId(),
            width: cellWidth,
            colspan: 1,
            styles,
            contenteditable:
              ('border-bottom' in styles || 'border-width' in styles) &&
              !!field,
          });
        }
        return prev;
      }, []);
    },
    /**
     * @description: 解析cell单元格样式
     * @param {*} cell
     * @return {*}
     */
    createStyles(cell: CellWithMorePros) {
      const {
        style: { alignment = {}, border = {}, font = {} },
      } = cell;
      return {
        'text-align': alignment.horizontal || 'center',
        'vertical-align': alignment.vertical || 'bottom',
        ...this.createBorder(border),
        font: `${font.bold ? 'bold' : 'normal'} ${font.size || 12}px ${
          font.name || '宋体'
        }`,
      };
    },
    /**
     * @description: 解析单元格value
     * @param {*}
     * @return {*}
     */
    createValue(value: ValueType): string {
      return typeof value === 'object'
        ? value.richText?.reduce((p, c) => p + c.text?.trim(), '')
        : value?.trim().replace(/\s+/g, ' ');
    },
    /**
     * @description: 解析cell单元格边框
     * @param {*} border
     * @return {*}
     */
    createBorder(border: Partial<Borders>) {
      return Object.entries(border).reduce<Record<string, string>>((p, c) => {
        const [k, v] = c;
        // if (k === "bottom") {}
        p[`border-${k}`] = `1px solid ${
          v?.color?.argb?.replace('FF', '#') || '#000000'
        }`;
        return p;
      }, {});
    },
    cancel() {
      this.$emit('update:visible', false);
    },
    containerChange() {
      if (this.$attrs.isFullScreen) {
        return document.getElementsByClassName('full-dialog')[0];
      } else {
        return document.getElementsByClassName('export-dialog')[0];
      }
    },
  },
});
