/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2022-05-05 10:23:12
 * @LastEditors: renmingming
 * @LastEditTime: 2022-05-06 14:17:40
 */

export enum PdfPageType {
  coverPage = 'cover', // 封面
  titlePage = 'titlePage', // 扉页
  description = 'constructOfferDesc', // 项目说明
}

export type CoverPageType = {
  coverTender: string; // 招标人
  coverManagementDate: string; // 编制时间
  showCoverConstructName: boolean; // 是否显示工程名称
  showCoverConstructTotal: boolean; // 是否显示工程总价
  showCoverTender: boolean; // 是否显示招标人
  showCoverManagerDate: boolean; // 是否显示编制时间
};

export type TitlePageType = {
  titleTender: string; // 招标人
  titleBidder: string; // 投标人
  titleRepresentative: string; // 法定代表人
  titleCostEngineer: string; //注册造价工程师
  titleManagementDate: string; // 编制时间
  showTitle: boolean;
  showTitleConstruct: boolean;
  showTitleTotal: boolean;
  showTitleBidder: boolean;
  showTitleRepresentative: boolean;
  showTitleCostEngineer: boolean;
  showTitleManagementDate: boolean;
};

export interface EditPdfOptionType {
  cover: CoverPageType;
  titlePage: TitlePageType;
  constructOfferDesc: {
    description: string;
  };
}

export interface EditPdfSaveType extends EditPdfOptionType {
  constructId: string;
}
